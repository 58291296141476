import { graphql } from 'gatsby';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import Scrollspy from 'react-scrollspy';
import slugify from 'slugify';
import SEO from '~/components/Global/SEO';
import Layout from '~/components/Layout';

const slugifyLower = (slug) =>
  typeof slug === 'string' ? slugify(slug, { lower: true }) : '';

const Legal: FunctionComponent = ({ data }): ReactElement => {
  const pageData = data.wpPage;
  if (!pageData?.legalV2?.headings?.length) {
    return null;
  }
  const legalSectionsMapped = pageData?.legalV2?.headings;

  const [flat, setFlat] = useState([]);

  useEffect(() => {
    setFlat([]);
    legalSectionsMapped.forEach((rs) => {
      setFlat((f) => [
        ...f,
        {
          heading: rs.heading,
          slug: slugifyLower(rs.heading),
          type: 'heading',
        },
      ]);
      rs.posts.forEach((p) => {
        setFlat((f) => [
          ...f,
          { heading: p.heading, slug: slugifyLower(p.heading), type: 'post' },
        ]);
      });
    });
  }, [data]);

  return (
    <Layout>
      <SEO title={'Legal'} />
      <section className="py-12 my-0 bg-lgrey md:py-24 md:pt-48 md:-mt-24">
        <div className="container">
          <div className="section-tag">Legal</div>
          <h1
            dangerouslySetInnerHTML={{ __html: 'Our policies & practices' }}
          />
          <div
            className="max-w-md"
            dangerouslySetInnerHTML={{
              __html:
                'Learn about the legal side of Percent. Here’s where you’ll find information on our terms of business.',
            }}
          />
        </div>
      </section>

      <main>
        <section className="py-12 md:py-24">
          <div className="container">
            <div className={`flex flex-wrap`}>
              {flat.length > 0 ? (
                <aside className="hidden mb-12 md:w-1/4 md:mb-0 md:block">
                  <div
                    className="sticky p-8 scroll-nav top-24 overflow-y-auto"
                    style={{ maxHeight: 'calc(100vh - 120px)' }}
                  >
                    {/* <div className="p-0 mb-0 bg-white section-tag">Contents</div> */}
                    <Scrollspy
                      style={{ listStyle: 'disc', marginLeft: '1rem' }}
                      items={flat.map(({ slug }) => slug)}
                      offset={-120}
                      currentClassName="is-current"
                    >
                      {flat.map(({ heading, slug, type }, i) => (
                        <li
                          key={`legallink${i}`}
                          style={{
                            listStyle: type === 'heading' ? 'none' : '',
                          }}
                        >
                          {type === 'heading' ? (
                            <div
                              className={`${
                                i > 0 ? 'mt-6' : ''
                              } font-medium text-20px `}
                              style={{ marginLeft: '-1rem', cursor: 'default' }}
                              dangerouslySetInnerHTML={{ __html: heading }}
                            />
                          ) : (
                            <a
                              className="block "
                              href={`#${slug}`}
                              style={{ lineHeight: 1.3 }}
                              dangerouslySetInnerHTML={{
                                __html: heading,
                              }}
                            />
                          )}
                        </li>
                      ))}
                    </Scrollspy>
                  </div>
                </aside>
              ) : null}

              <div className="w-full md:w-3/4 md:pl-12 lg:px-24">
                {legalSectionsMapped.map(({ posts }, i) => (
                  <div key={`heading${i}`}>
                    {posts.map(({ heading, post }, i2) => (
                      <div
                        key={`legal-section-${i2}`}
                        className="relative mb-20"
                      >
                        <div
                          id={slugifyLower(heading)}
                          className="absolute inset-0 pointer-events-none"
                          style={{ top: '-120px' }}
                        />
                        <h3 dangerouslySetInnerHTML={{ __html: heading }} />
                        <div
                          className="my-6 prose legal-prose"
                          dangerouslySetInnerHTML={{ __html: post?.content }}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Legal;

export const query = graphql`
  query LegalV2Template($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      legalV2 {
        headings {
          heading
          posts {
            heading
            post {
              ... on WpPage {
                title
                content
              }
            }
          }
        }
      }
    }
  }
`;
